import { AppSettings } from "../Constants/AppSettings"
import { baseResponse } from "../DataTypes/baseResponse"

const noInternetConnectionError:baseResponse = {
    data: [],
    status: false,
    statusCode: 0,
    statusMessage: 'The same internet connection error'
}

const duringParsingError:baseResponse = {
    data: [],
    status: false,
    statusCode: 1,
    statusMessage: 'Error during parsing json response'
}

export const fetchData = async (
    url = '',
    type = 'POST',
    body = {},
    formData: boolean = false
) => {
    try{
        
        var bodySend:{body?: any, headers?: any} = {}
        if(formData){
            bodySend.body = body
        } else {
            if(type !== "GET"){
                bodySend.body = JSON.stringify(body)
            }
            bodySend.headers = {
                'Content-Type': 'text/plain',
            }
        }
        const response = await fetch(
            `${AppSettings.endpoint}/${url}`,
            {
                method: type,
                ...bodySend
            }
        )
        if (response.ok) {
            try{
                const json = await response.text();
                return JSON.parse(json);
            } catch(e){
                return duringParsingError
            }
        } else {
            console.log('!OK')
            return response
        }
    } catch(e){
        console.log('error fetch', e)
        return noInternetConnectionError
        // throw e
    }
}

