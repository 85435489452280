import { Model } from "../Components/Models/Model"
import { FacebookLoginModel } from "./SocialMethods/FacebookLoginModel";
import { GoogleLoginModel } from "./SocialMethods/GoogleLoginModel"

class SocialController extends Model {

    private _googleLogin: GoogleLoginModel;
    private _facebookLogin: FacebookLoginModel;

    constructor(){
        super();
        this._googleLogin = new GoogleLoginModel()
        this._facebookLogin = new FacebookLoginModel()
    }

    get googleLogin(){
        return this._googleLogin
    }

    get facebookLogin(){
        return this._facebookLogin
    }
}

export { SocialController }