import React from 'react'
import { ViewItem } from '../../Components/Views/ViewItem'
import { MenuController } from '../../Controllers/MenuController'

class MenuView extends ViewItem {

    get controller(): MenuController{
        return this.props.controller
    }

    render(){
        return <div className="menu">
            <div className="menu-box">
                
            </div>
        </div>
    }
}

export { MenuView }