import React from 'react'
import { UpdateComponent } from '../Components/Views/UpdateComponent'
import { controllers } from '../Controllers/Controllers'
import { UPDATE } from '../DataTypes/UPDATE'
import { AuthStack } from '../Views/ContentView/Auth/AuthStack'
import { FooterView } from '../Views/FooterView/FooterView'
import { MenuView } from '../Views/MenuView/MenuView'

class Core extends UpdateComponent {

    constructor(props: any){
        super(props)
        this.type = UPDATE.AUTH
    }
    render(){
        const {isLogin} = controllers().auth
        if(!isLogin){
            return <AuthStack/>
        }
        return <div className="core">
            <div className="core-box">
                <div className="menu-backblock"/>
                <MenuView controller={controllers().menu} ref={controllers().menu.set}/>
                <div className="core-content" onClick={controllers().media.mainContentClicked}>
                    <div className="core-content-box">
                        {this.props.children}
                    </div>
                </div>
                <FooterView/>
            </div>
        </div>
    }
}

export { Core }