import React from "react";

class FooterView extends React.Component {

    render(){
        return <div className="footer">
        <div className="footer-box">
            
        </div>
    </div>
    }
}

export { FooterView }