// import { BlockList } from "./PageControllers/BlockList";
// import { Dashboard } from "./PageControllers/Dashboard"
// import { Plan } from "./PageControllers/Plan";
// import { ProxyConfig } from "./PageControllers/ProxyConfig";

import { AuthPageModel } from "./PageControllers/AuthStack/AuthPageModel";
import { RegisterPageModel } from "./PageControllers/AuthStack/RegisterPageModel";
import { UploadPage } from "./PageControllers/MainStack/UploadPage";


class PageController {
    
    private readonly _auth: AuthPageModel
    private readonly _register: RegisterPageModel

    private readonly _uploadPage: UploadPage

    constructor(){
        this._auth = new AuthPageModel('auth');
        this._register = new RegisterPageModel('auth');
        this._uploadPage = new UploadPage('Upload file')
    }

    get auth(){
        return this._auth
    }

    get register(){
        return this._register
    }

    get upload(){
        return this._uploadPage
    }
}

export { PageController }