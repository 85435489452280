import { ErrorMessageView } from "../../../Components/Views/UI/ErrorMessage/ErrorMessageView";
import { LButton } from "../../../Components/Views/UI/LButton";
import { LForm } from "../../../Components/Views/UI/LForm";
import { LInput } from "../../../Components/Views/UI/LInput";
import { SuccessMessageView } from "../../../Components/Views/UI/SuccessMessage/SuccessMessageView";
import { navigator } from "../../../Controllers/Navigator";
import { RegisterPageModel } from "../../../Controllers/PageControllers/AuthStack/RegisterPageModel";
import { PageView } from "../../../Core/PageView";
import './auth.css'
import { SocialAuthView } from "./SocialAuth";

class RegisterPageView extends PageView {

    get controller(): RegisterPageModel{
        return this.props.controller
    }
    componentWillUnmount(){
        this.controller.willUnmount()
    }
    
    render() {
        const { fv, onSubmitRegister, error, success} = this.controller
        return <div className="page auth-page">
            <div className="auth-box design-bg">
                <SuccessMessageView ref={success.set} controller={success}>
                    <LForm onSubmit={onSubmitRegister}>
                        <div className="title">
                            <div className="title-box">
                                Registration
                            </div>
                        </div>
                        <div className="auth-inputs">
                            <LInput
                                ref={(ref)=>fv.set(ref)}
                                title="Email" type="email" 
                                placeholder="Enter email address" 
                                name="email" customStyle="user-auth-input"
                                required
                            />
                            <LInput 
                                ref={(ref)=>fv.set(ref)}
                                title="Password" 
                                type='password' placeholder="Enter password" 
                                name="password" customStyle="user-auth-input" 
                                required
                            />
                            <LInput 
                                ref={(ref)=>fv.set(ref)}
                                title="Confirm password" 
                                type='password' placeholder="Enter password" 
                                name="confirmpassword" customStyle="user-auth-input" 
                                required
                            />
                        </div>
                        <ErrorMessageView ref={error.set} controller={error}/>
                        <div className="auth-buttons">
                            <LButton
                                ref={fv.setButton}
                                onClick={()=>{}}
                                title="Create account"
                                type="suit"
                                icon="user-plus"
                                iconColor="white"
                                submit
                            />
                            <LButton
                                onClick={()=>{
                                    navigator().navigate('/')
                                }}
                                title="Sign In"
                                type="secondary"
                            />
                        </div>
                    </LForm>
                    <SocialAuthView/>
                </SuccessMessageView>
            </div>
        </div>
    }
}

export { RegisterPageView }