import { PageModel } from "../PageModel";
import { UploadFile } from "./UploadPage/UploadFile";


class UploadPage extends PageModel {

    private _uploadFile: UploadFile

    constructor(pageName: string){
        super(pageName)
        this._uploadFile = new UploadFile();
    }

    get uploadFile(){
        return this._uploadFile
    }
}

export { UploadPage }