import { MButton } from "./MButton";
import { MInput } from "./MInput"
import { TargetForm } from "./TargetForm"


class FormValidator {
    private _inputs:Array<MInput>

    private _submitButton: MButton;
    private _form: TargetForm|null;

    constructor(){
        this._inputs = []
        this._submitButton = new MButton()
        this._form = null
    }

    setButton = (ref: any) => {
        this._submitButton.setRef(ref)
    }
    beforeRequest = () => {
        this._submitButton.load(true)
    }
    afterRequest = () => {
        this._submitButton.load(false)
    }

    append = (ref: any) => {
        try{
            if(ref === null){
                return
            }
            const name = ref.getName 
            const find = this._inputs.find(inp => inp.id === name)
            if(find){
                find.updateRef(ref)
            } else {
                this._inputs.push(new MInput({id: name, ref: ref}))
            }
        } catch(e){
            // console.error(e)
        }
    }

    set = (ref:any) => {
        this.append(ref)
    }

    get inputs(){
        return this._inputs
    }

    get target(){
        return this._form
    }

    form = (e:any, beforeRequest = true) => {
        this.validate()
        if(!this.isValid()){
            this.focusFirst()
            return false
        }
        this._form = new TargetForm(e)
        if(beforeRequest){
            this.beforeRequest()
        }
        return true
    }

    focusFirst = () => {
        const find = this._inputs.find(inp => !inp.isValid)
        if(find){
            find.focus()
        }
    }

    validate = () => {
        this._inputs.forEach(inp => {
            inp.validate()
        })  
    }

    isValid = () => {
        return this._inputs.find(inp => !inp.isValid) === undefined
    }

    get body(){
        return this._form?.body
    }
}

export { FormValidator }