import React from 'react'

type viewItemProps = {
    component?: any;
    children?: any;
    RightComponent?: any;
    controller?: any
    match?:any
}

class ViewItem extends React.Component {
    props: viewItemProps
    constructor(props:viewItemProps){
        super(props)
        this.props = props
    }
    update = () => {
        this.forceUpdate()
    }
}

export { ViewItem }