import { Model } from "../Components/Models/Model";
import { MenuItemModel, menuItemType } from "./Menu/MenuItemModel";

const menuItems: Array<menuItemType> = [
    {
        name: "iptv",
        title: 'Online IPTV',
        link: '/iptv',
    },
    {
        name: "films",
        title: 'Films',
        link: '/films',
    },
    {
        name: "series",
        title: 'Series',
        link: '/series',
    },
    {
        name: "favorites",
        title: 'Favorites',
        link: '/favorites',
        authItem: true,
        new: true
    },
    
    
]

class MenuController extends Model {
    private _items: Array<MenuItemModel>

    constructor(){
        super()
        this._items = menuItems.map(item=>new MenuItemModel(item))
    }

    get menuItems(){
        return this._items
    }


    page(str:string){
        this.removeSelected()
        const find = this.menuItems.find(p=>p.name===str)
        if(find){
            find.setSelected(true)
        }
    }

    removeSelected(){
        const find = this._items.find(e=>e.selected)
        if(find!==undefined){
            find.setSelected(false)
        }
    }
}

export { MenuController }