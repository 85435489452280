import React from "react";
import { Switch ,Route } from 'react-router-dom';
import { controllers } from "../../../Controllers/Controllers";
import { AuthPageView } from "./AuthPage";
import { ConfirmPasswordPage } from "./ConfirmPasswordPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { NewPasswordPage } from "./NewPasswordPage";
import { RegisterPageView } from "./RegisterPage";

class AuthStack extends React.Component {

    render(): React.ReactNode {
        const {auth, register} = controllers().page
        const { forgotPassword, confirmForgot, newPassword} = controllers().register
        return <Switch>
            <Route path="/newpassword/:hash" component={
                (match:any)=><NewPasswordPage match={match} ref={newPassword.set} controller={newPassword}/>
            } />
            {/* <Route path="/unsubscribe/:hash" component={
                (match:any)=><Unsubscribe match={match}/>
            } /> */}
            <Route path="/forgotpassword/:hash" component={
                (match:any)=><ConfirmPasswordPage match={match} ref={confirmForgot.set} controller={confirmForgot}/>
            } />
            <Route path="/forgotpassword">
                <ForgotPasswordPage ref={forgotPassword.set} controller={forgotPassword}/>
            </Route>
            {/* <Route path="/verify_email/:hash" component={
                (match:any)=><VerifyEmail match={match}/>
            } /> */}
            <Route path="/register">
                <RegisterPageView controller={register}/>
            </Route>
            <Route path="/">
                <AuthPageView controller={auth}/>
            </Route>
        </Switch>
    }
}

export { AuthStack }