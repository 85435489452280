import { ErrorMessage } from "../../../Components/Models/Validators/ErrorMessage";
import { FormValidator } from "../../../Components/Models/Validators/FormValidator";
import { controllers } from "../../Controllers";
import { PageModel } from "../PageModel";


class AuthPageModel extends PageModel {

    private _formValidator: FormValidator;
    private _error: ErrorMessage;
    constructor(pageName: string){
        super(pageName)
        this._formValidator = new FormValidator();
        this._error = new ErrorMessage();
    }

    get fv(){
        return this._formValidator
    }

    get error(){
        return this._error
    }

    onAuthSubmit = async (e:any) => {
        const { fv } = this
        const valid = fv.form(e)
        if(valid){
            const login = fv.target?.getInputValue('login')||""
            const password = fv.target?.getInputValue('password')||""
            const response = await controllers().auth.signIn(login,password)
            fv.afterRequest()

            if(!response.status){
                this._error.showError(response.message)
            }
        }
    }

    willUnmount = () => {
        this._error.clearError(false)
    }
}

export { AuthPageModel }