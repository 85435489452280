import { Model } from "../Model";


class ErrorMessage extends Model {

    private _error: boolean
    private _errorMessage: string;

    constructor(){
        super()
        this._error = false
        this._errorMessage = ""
    }

    showError = (message: string, update: boolean = true) => {
        this._error = true
        this._errorMessage = message
        if(update){
            this.updateMe()
        }
    }

    clearError = (update: boolean = true) => {
        this._error = false
        this._errorMessage = ""
        if(update){
            this.updateMe()
        }
    }

    get error(){
        return this._error
    }

    get errorMessage(){
        return this._errorMessage
    }
}

export { ErrorMessage }