import { ErrorMessageView } from "../../../Components/Views/UI/ErrorMessage/ErrorMessageView";
import { LButton } from "../../../Components/Views/UI/LButton";
import { LForm } from "../../../Components/Views/UI/LForm";
import { LInput } from "../../../Components/Views/UI/LInput";
import { navigator } from "../../../Controllers/Navigator";
import { AuthPageModel } from "../../../Controllers/PageControllers/AuthStack/AuthPageModel";
import { PageView } from "../../../Core/PageView";
import './auth.css'
import { SocialAuthView } from "./SocialAuth";

class AuthPageView extends PageView {

    get controller(): AuthPageModel{
        return this.props.controller
    }

    render() {
        const { fv, onAuthSubmit, error} = this.controller
        return <div className="page auth-page">
            <div className="auth-box design-bg">
                <LForm onSubmit={onAuthSubmit}>
                    <div className="title">
                        <div className="title-box">
                            Welcome
                        </div>
                    </div>
                    <div className="auth-inputs">
                        <LInput
                            ref={fv.set}
                            title="Email" type="email" 
                            placeholder="Enter email address" 
                            name="login" customStyle="user-auth-input"
                            required
                        />
                        <LInput 
                            ref={fv.set}
                            title="Password" 
                            type='password' placeholder="Enter password" 
                            name="password" customStyle="user-auth-input" 
                            required
                        />
                        <div className="forgot-button-box">
                            <LButton 
                                onClick={()=>{
                                    navigator().navigate('/forgotpassword')
                                }}
                                title="Forgot password?"
                                type="none"
                                customStyle="forbot-button"
                            />
                        </div>
                    </div>
                    <ErrorMessageView ref={error.set} controller={error}/>
                    <div className="auth-buttons">
                        <LButton
                            ref={fv.setButton}
                            onClick={()=>{}}
                            title="Sign In"
                            icon="right-to-bracket"
                            iconColor="white"
                            type="suit"
                            submit
                        />
                        <LButton
                            onClick={()=>{
                                navigator().navigate('/register')
                            }}
                            title="Create account"
                            type="secondary"
                        />
                    </div>
                </LForm>
                <SocialAuthView/>
            </div>
        </div>
    }
}

export { AuthPageView }