import { AlertController } from "./AlertController";
import { AppController } from "./AppController";
import { AuthController } from "./AuthController";
import { LanguageController } from "./LanguageController";
import { MediaController } from "./MediaController";
import { MenuController } from "./MenuController";
import { PageController } from "./PageController";
import { RegisterController } from "./RegisterController";
import { SocialController } from "./SocialController";

class ControllersImpl {
  
    private _app: AppController
    private _media: MediaController;
    private _alert: AlertController;
    private _menu: MenuController;
    private _page: PageController;
    private _language: LanguageController;
    
    private _auth: AuthController;
    private _register: RegisterController;
    private _social: SocialController
    
    constructor() {
        this._app = new AppController();
        this._media = new MediaController();
        this._alert = new AlertController()
        this._menu = new MenuController();
        this._page = new PageController();
        this._language = new LanguageController();

        this._auth = new AuthController();
        this._register = new RegisterController();
        this._social = new SocialController();
    }

    get app(){
        return this._app
    }
    
    get media(){
        return this._media
    }

    get alert(){
        return this._alert
    }

    get menu(){
        return this._menu
    }

    get page(){
        return this._page
    }

    get language(){
        return this._language
    }

    get auth(){
        return this._auth
    }

    get register(){
        return this._register
    }

    get social(){
        return this._social
    }

}
  
// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.controllers =
// @ts-ignore
global.__app__.controllers || new ControllersImpl();

export function controllers(): ControllersImpl {
    // @ts-ignore
    return global.__app__.controllers;
}
  