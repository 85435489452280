import { LButton } from "../../../../Components/Views/UI/LButton";
import { ViewItem } from "../../../../Components/Views/ViewItem";
import { BUTTONS } from "../../../../Constants/buttons";
import { FacebookLoginModel } from "../../../../Controllers/SocialMethods/FacebookLoginModel";


class FacebookAuthView extends ViewItem {

    get controller():FacebookLoginModel{
        return this.props.controller
    }

    componentDidMount() {
        this.controller.loadApi()
    }

    render() {
        const { onClickButton, buttonClicked } = this.controller
        return <LButton 
                    onClick={onClickButton}
                    title="Facebook"
                    CustomImage={BUTTONS.facebook}
                    orderImage={-1}
                    type="none"
                    customStyle="auth-button icon-button facebook-button"
                    loadingNow={buttonClicked}
                />
    }
}

export { FacebookAuthView }