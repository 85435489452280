import React from "react";
import { colorTypes } from "../../../DataTypes/AlertTypes";
import { Fa } from "../Fa";
import { ImageView } from "../ImageView";
import { LangItem } from "../LangItem";
import { Loading } from "../Loading";
import '../../../assets/styles/components/buttons.css'
import { COLORS } from "../../../Constants/colors";

export type lButton = {
    customStyle?: string;
    title?: string;
    type?: colorTypes;
    onClick: (any:any)=>void;
    icon?: string;
    iconColor?: string;
    iconSize?: string;
    iconType?: "fas"|"fab"
    CustomImage?: any;
    orderImage?: number;
    tabindex?: number;
    new?: boolean;
    disabled?: boolean;
    loadingNow?: boolean;
    submit?: boolean
    tooltip?: {
        message: string;
        customStyle?: string;
    };
}

type stateLButton = {
    loading: boolean
}
class LButton extends React.Component {
    props: lButton;
    state: stateLButton
    constructor(props: lButton){
        super(props)
        this.props = props
        this.state = {
            loading: false
        }
        this.load = this.load.bind(this)
    }
    load(bool:boolean){
        this.setState({
            loading: bool
        })
    }
    render(){
        const {...p} = this.props
        const {loading} = this.state
        if(loading || p.loadingNow){
            return <div className={`button button-type-${p?.type} ${p?.customStyle}`} >
                <button className="button-box" disabled>
                    <Loading color={p?.iconColor || COLORS.suit}/>
                </button>
            </div>
        }
        return <div className={`button button-type-${p?.type} ${p?.customStyle} ${p?.disabled ? 'button-disabled':''}`} >
            <button className="button-box" onClick={p.onClick} tabIndex={p?.tabindex} disabled={p?.disabled} type={p?.submit ? "submit": "button"}>
                {p?.tooltip && <div className={`tooltip ${p.tooltip.customStyle}`}>
                    <div className="tooltip-box">
                        <LangItem for={p.tooltip.message}/>
                    </div>
                </div>}
                {p?.new && <div className="new-tag"/>}
                {p?.icon && <div className="button-icon">
                    <Fa icon={p.icon} color={p?.iconColor} size={p?.iconSize} type={p?.iconType}/>
                </div>}
                {p?.title && <div className="button-title">
                    <LangItem for={p.title}/>
                </div>}
                {p?.CustomImage && <div className={`button-icon order-${p?.orderImage}`}>
                    <ImageView source={p.CustomImage} alt="no photo"/>
                </div>}
            </button>
        </div>
    }
}

export {LButton}