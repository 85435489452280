import { HandleTask } from "../Components/Models/HandleTask/HandleTask";
import { coreupdate } from "../Constants/Actions";
import { UPDATE } from "../DataTypes/UPDATE";
import { Dictionary } from "./Dictionaries/Dictionary";
import { LiveCategoryDictionary } from "./Dictionaries/LiveCategoryDictionary"

interface T extends Dictionary{};

class Dictionaries {

    private _dicts: Array<T>
    private _liveCategory: LiveCategoryDictionary;
    private _handler: HandleTask;

    constructor(){
        this._liveCategory = new LiveCategoryDictionary();
        this._handler = new HandleTask()
        this._dicts = [this._liveCategory]
    }

    loadAll = async () =>{
        await this._dicts.forEach(async (d)=>{
            await d.loadData()
            if(this.checkIfAllLoaded()){
                this.afterLoad()
            }
        })
    }

    afterLoad = () => {
        this._handler.do()
        coreupdate(UPDATE.DICTIONARY)
    }

    checkIfAllLoaded = () => {
        return this._dicts.find(d=>!d.loaded)===undefined
    }

    get liveCategory(){
        return this._liveCategory
    }

    get handler(){
        return this._handler
    }
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.dictionary =
// @ts-ignore
global.__app__.dictionary || new Dictionaries();

export function dictionary(): Dictionaries {
  // @ts-ignore
  return global.__app__.dictionary;
}
