import { UploadPage } from "../../../../Controllers/PageControllers/MainStack/UploadPage";
import { PageView } from "../../../../Core/PageView";
import { UploadFileView } from "./UploadFileView";


class UploadPageView extends PageView {

    get controller():UploadPage {
        return this.props.controller
    }
    render() {
        const { uploadFile } = this.controller
        return <div className="page uploadPage">
            <UploadFileView ref={uploadFile.set} controller={uploadFile}/> 
        </div>
    }
}

export { UploadPageView }