import { HandleTask } from "../Components/Models/HandleTask/HandleTask";
import { coreupdate } from "../Constants/Actions";
import { loadData, UserDataProvider } from "../Core/UserDataProvider";
import { authBody, facebookAuthBody, googleAuthBody, tokenBody } from "../DataTypes/authResponse";
import { langs } from "../DataTypes/languageTypes";
import { UPDATE } from "../DataTypes/UPDATE";
import { readData } from "../Helpers/readData";
import { saveData } from "../Helpers/saveData";
import { controllers } from "./Controllers";

export type signInResponseType = {
    status: boolean;
    message: string;
}

type userInfoType = {

    // country: string;
    // dateEntered: string;
    // email: string;
    // endDate: string;
    // firstname: string;
    // is_default_password: string;
    // magyarname: string;
    // phonenumber: string;
    // status: string;
    // user_name: string;
    // user_referal: string;
    // nickname: string;
    language: langs;
    email: string;
    name: string;
    photo: string;
    username: string;
}

class AuthController {
    private _isLogin:boolean;
    private _userToken: string | null
    private _userInfo: userInfoType | null;
    private _remindMe: any;
    private _authHandlerTasks: HandleTask;
    private _lastLiveChannel: string | null;
    private _triedToResporeData: boolean;
    private _resoreDataHandler: HandleTask;

    constructor(){
        this._isLogin = false
        this._userToken = null
        this._userInfo = null
        this._triedToResporeData = false
        this._resoreDataHandler = new HandleTask();
        this._authHandlerTasks = new HandleTask();
        this._lastLiveChannel = null
        this.checkToken = this.checkToken.bind(this)
        this.signOut = this.signOut.bind(this)
        this.signIn = this.signIn.bind(this)
        this.getUserInfo = this.getUserInfo.bind(this)
        this.setRememberMe = this.setRememberMe.bind(this)
    }

    get restoreDataHandler(){
        return this._resoreDataHandler
    }

    get triedToResporeData(){
        return this._triedToResporeData
    }

    setTriedToRestoreData = (bool: boolean) => {
        this._triedToResporeData = bool;
    }

    get tasks(){
        return this._authHandlerTasks
    }

    setRememberMe(ref: any){
        this._remindMe = ref
    }

    get rememberMe(){
        try{
            return this._remindMe.state.checked
        } catch(e){
            return false
        }
    }

    get userInfo(){
        return this._userInfo
    }

    // dateWasEnded = () => {
    //     const {userInfo} = this
    //     const endDaysLeft = userInfo !== null &&  userInfo?.endDate ? stringParser(dayDifferenceFromNow(DateParse(userInfo?.endDate||""))) : 0
    //     return endDaysLeft === 0
    // }

    get isLogin(){
        return this._isLogin
    }
    
    get userToken(){
        return this._userToken
    }

    get lastLiveChannel(){
        return this._lastLiveChannel
    }

    setLastLiveChannel(val:string = ''){
        this._lastLiveChannel = val
        this.saveMe()
    }

    get all(){
        return {
            isLogin: this._isLogin,
            userToken: this.userToken,
            userInfo: this._userInfo,
            lastLiveChannel: this._lastLiveChannel,
            language: this.language
        }
    }

    // FACEBOOK AUTH 
    facebookSignIn = async (facebookData: facebookAuthBody) => {
        const authBody: googleAuthBody = {
            access_token: facebookData.accessToken,
            expires_at: facebookData.data_access_expiration_time,
            expires_in: facebookData.expiresIn,
            id_token: facebookData.signedRequest,
            userID: facebookData.userID
        }
        const response = await loadData(UserDataProvider.facebookAuthorization, authBody)

        if(response.statusCode !== 200){
            console.error("Bad request", response)
            return
        }
        console.log("FACEBOOK SIGN IN", response)
        this.login(response.data.userToken)
    }

    //GOOGLE AUTH
    googleSignIn = async (googleData: googleAuthBody) => {
        const response = await loadData(UserDataProvider.googleAuthorization, googleData)

        if(response.statusCode !== 200){
            console.error("Bad request", response)
            return
        }
        console.log("GOOGLE SIGN IN", response)
        this.login(response.data.userToken)
    }
    // sign in btn press action
    async signIn(login:string, password:string){
        const authBody: authBody = {
            login: login,
            password: password,
            remember_me: this.rememberMe
        }
        console.log('authBody', authBody)
        const response = await loadData(UserDataProvider.authorization,authBody);
        console.log('response', response)
        if(response.statusCode !== 200 || !response?.data?.userToken){
            // controllers().alert.error(response.statusMessage)
            return {
                status: false,
                message: response.statusMessage && response.statusMessage!==""? response.statusMessage : "Something went wrong"
            }
        }
        this.login(response?.data?.userToken)
        return {
            status: true,
            message: response.statusMessage
        }
    }
    // do private auth with token
    private login(userToken:string){
        if(!userToken){
            return false
        }
        this._isLogin = true
        this._userToken = userToken;
        this.getUserInfo()
        this.saveMe()
        this.updateMe()
        this.afterAuth()
        // navigator().navigate('dashboard')
    }

    afterAuth = () => {

    }

    async signOut(){

        const tokenBody: tokenBody = {
            userToken: this._userToken||""
        }
        console.log('tokenBody', tokenBody)
        // sync request to server
        loadData(UserDataProvider.logout, tokenBody);
        // console.log('tokenBody response', response)
        // clear storage update page
        this.logout()

    }

    private logout(){
        this._isLogin = false
        this._userToken = null;
        this._userInfo = null;
        this.saveMe()
        this.updateMe()
        this.updateUser()
    }

    async checkToken(){
        if(this._userToken===null){
            return 
        }
        const tokenBody: tokenBody = {
            userToken: this._userToken||""
        }
        // sync request to server
        const response = await loadData(UserDataProvider.check, tokenBody);
        // wait success token
        if(response.statusCode===200){
            return true
        }
        console.warn("CHECK TOKEN")
        if(this._isLogin){
            this.logout()
        }
        // if not 200 logout
        return false
    }

    getUserInfo = async () => {
        const tokenBody: tokenBody = {
            userToken: this._userToken||""
        }
        // sync request to server
        const response = await loadData(UserDataProvider.userInfo, tokenBody);
        console.log("USERINFO", response)
        // wait success token
        if(response.statusCode===200){
            const data:userInfoType = response.data
            this._userInfo = data
            this._authHandlerTasks.do()
            this.updateUser()
        }

        // console.log("USER INFO", this._userInfo)
        // if not 200 logout
        // this.logout()
    }

    private saveMe = async () => {
        const userData = JSON.stringify(this.all);
        await saveData('secureUserData', userData)
        return true
    }

    restoreMe = async () => {
        const data = await this.readData()
        if(!data){
            return
        }
        try{
            const userinfo = JSON.parse(data)
            // console.log('restoreMe userinfo',userinfo)
            this._isLogin = userinfo?.isLogin
            this._userToken = userinfo?.userToken
            this._userInfo = userinfo?.userInfo
            controllers().language.setLanguage(userinfo?.language || "en")
            this._lastLiveChannel = userinfo?.lastLiveChannel
            if(this._isLogin){
                this.updateMe();
                this.updateUser();
            }
            this.setTriedToRestoreData(true)
            this._resoreDataHandler.do()
        } catch(e){
            console.log('error restore data')
        }
    }

    get language(){
        return controllers().language.selectedLanguage
    }

    saveLanguage = () => {
        this.saveMe()
    }
    
    private async readData(): Promise<any> {
        const data = await readData('secureUserData');
        return data
    }

    updatePhoto = (photoPath: any) => {
        if(this.userInfo === null){
            return
        }
        this.userInfo.photo = photoPath
        this.updateUser()
    }

    private updateMe(){
        coreupdate(UPDATE.AUTH)
    }

    private updateUser(){
        coreupdate(UPDATE.USERINFO)
    }

}

export { AuthController }