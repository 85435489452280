// import { loadData, UserDataProvider } from "../../Core/UserDataProvider";
import { Dictionary } from "./Dictionary";


class LiveCategoryDictionary extends Dictionary {

    async query(){
        // const response = await loadData(UserDataProvider.loadCategoryLives, {})

        // if(!response.data || !response.data.length){
            // return []
        // }
    
        // return response.data
        
        return []
    }

    get selectOptions(){
        return this.items.map(i=>{
            return {
                label: i.genre,
                value: i.id
            }
        })
    }
}

export { LiveCategoryDictionary }