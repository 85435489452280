import { SuccessMessage } from "../../Components/Models/Validators/SuccessMessage";
import { loadData, UserDataProvider } from "../../Core/UserDataProvider";
import { navigator } from "../Navigator";
import { BaseAuthController } from "./BaseAuthController";


class NewPassword extends BaseAuthController {
    private _hash: string
    constructor(){
        super()
        this._hash = ""
        this.success = new SuccessMessage({
            successMessage: "Password changed successfully",
            successButton: {
                onClick: this.backClick,
                title: "Sign In",
                type: "suit",
            }
        })
    }

    setHash = (hash:string) => {
        this._hash = hash
    }

    submitForm = async (e:any) => {
        const form = this.fv
        form.form(e, false)
        if(!form.target){
            return
        }
        if(form.target.getInputValue('password')!==form.target.getInputValue('confirmpassword')){
            this.showError(`Password don't match`)
            return
        }
        this.fv.beforeRequest()
        const response = await loadData(
            UserDataProvider.changePass,
            {
                ...form.body,
                emailToken: this._hash,
            }
        )
        this.fv.afterRequest()
        if(response.statusCode === 200){
            this.showSuccess()
        } else {
            this.showError(response.statusMessage)
        }
    }

    backClick = () => {
        // navigator().navigate('/forgotpassword')
        navigator().navigate('/')
    }

    willUnmount = () => {
        this.clearError(false)
    }
}

export { NewPassword }