import { idType } from "../../../DataTypes/dataTypes";

type mInputModel = {
    id: idType;
    ref: any;
}
class MInput {
    private _model: mInputModel

    private _isValid: boolean

    constructor(model: mInputModel){
        this._model = model
        this._isValid = true
    }

    get id(){
        return this._model.id
    }

    get ref(){
        return this._model.ref
    }

    updateRef = (ref: any) => {
        this._model.ref = ref
    }

    validate = () => {
        if(this._model.ref === null){
            return       
        }
        try{
            const lInput = this._model.ref

            if(lInput.required && lInput.inputRef.value === ""){
                this._isValid = false
                lInput.makeValidate(false)
            } else {
                this._isValid = true
            }

        } catch(e){
            console.error(e)
        }
    }

    focus = () => {
        if(this._model.ref === null){
            return       
        }

        this._model.ref.focus()
    }

    get isValid(){
        return this._isValid
    }
}

export { MInput }