import { LArea } from "../../../../Components/Views/UI/LArea";
import { LButton } from "../../../../Components/Views/UI/LButton";
import { LForm } from "../../../../Components/Views/UI/LForm";
import { ViewItem } from "../../../../Components/Views/ViewItem";
import { UploadFile } from "../../../../Controllers/PageControllers/MainStack/UploadPage/UploadFile";


class UploadFileView extends ViewItem {
    get controller(): UploadFile {
        return this.props.controller
    }
    render() {
        const { onSubmit, setFileRef, responseText} = this.controller
        return <div className="upload-file">
                <div className="upload-file-box">
                    <LForm onSubmit={onSubmit}>
                        <input ref={setFileRef} type="file" name="profilePhoto" accept="image/*"/>
                        <LButton
                            onClick={()=>{}}
                            title="Upload"
                            type="suit"
                            submit
                        />

                        <LArea title="Response Text" name="responseText" value={responseText} onChange={(e)=>{}}/>
                    </LForm>
                </div>
            </div>
    }
}

export { UploadFileView }