
type screen = {
    w: number;
    h: number;
}

class MediaController {
    private _screen: screen

    constructor(){
        this._screen = {
            w: window.innerWidth,
            h: window.innerHeight
        }
        this.reinit = this.reinit.bind(this)
    }

    reinit(){
        this._screen = {
            w: window.innerWidth,
            h: window.innerHeight
        }
        this.reinitFunctions()
        // console.log('reinit', this._screen)
    }

    reinitFunctions(){
        
    }

    mainContentClicked(){
        // controllers().menu.userDrawer.hide()
    }

    get w(){
        return this._screen.w
    }

    get h(){
        return this._screen.h
    }

    get screen(){
        return this._screen
    }

    get isMobile(){
        return this._screen.w<600
    }
}

export { MediaController}