

class MButton {

    private _ref: any

    constructor(){
        this._ref = null
    }

    setRef = (ref: any) => {
        this._ref = ref
    }
    
    get ref(){
        return this._ref
    }

    load = (bool: boolean) => {
        if(this._ref === null){
            return
        }

        this._ref.load(bool)
    }
}

export { MButton }