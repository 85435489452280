

class Dictionary {
    private _loaded: boolean;
    private _lastLoad: number;
    private _items: Array<any>

    constructor(){
        this._items = []
        this._lastLoad = 0
        this._loaded = false
        this.query = this.query.bind(this)
        this.loadData = this.loadData.bind(this)
    }

    async loadData() {
        if(this._loaded && this.compareTimes()){
            return this._loaded
        }
        this._items = await this.query()
        this._loaded = true
        return this._loaded
    }

    compareTimes = () => {
        const time = Math.round(new Date().getTime()/1000)
        if(time>this._lastLoad+(60*30)){
            this._lastLoad = time
            return false
        } else {
            return true
        }
    }

    async query():Promise<Array<any>> {
        throw new Error("Query doesn't set");
    }

    get items(){
        return this._items
    }

    get loaded(){
        return this._loaded
    }
}

export { Dictionary }