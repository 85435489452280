import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { controllers } from '../Controllers/Controllers'
import { navigator } from '../Controllers/Navigator'
import { UploadPageView } from '../Views/ContentView/Pages/UploadPage/UploadPageView'
import { Core } from './Core'

class MainNavigation extends React.Component {

    render(){
        const { upload } = controllers().page
        return <Router
            ref={(ref:any)=>{
                navigator().initNavigation(ref)
            }}
        >
            <Core>
                <Switch>
                    {/* <Route path="/profile">
                        <AuthControllerView/>
                    </Route> */}
                    {/* <Route path="/register/:hash" component={(match:any)=><RegisterPage match={match} controller={register}/>}/> */}
                    {/* <Route path="/register">
                        <RegisterPage controller={register}/>
                    </Route> */}
                    {/* <Route path="/verify_email/:hash" component={(match:any)=><VerifyEmail match={match}/>}/> */}
                    <Route path="/">
                        {/* <HomePage controller={home}/> */}
                        <UploadPageView controller={upload}/>
                    </Route>
                </Switch>
            </Core>
        </Router>
    }
}

export { MainNavigation }