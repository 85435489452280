import { ErrorMessage } from "../../../Models/Validators/ErrorMessage";
import { ViewItem } from "../../ViewItem";
import './errorMessage.css'

class ErrorMessageView extends ViewItem {

    get controller(): ErrorMessage{
        return this.props.controller
    }
    render() {
        const { error, errorMessage } = this.controller
        if(!error){
            return null
        }
        return <div className='error-message'>
                <div className='error-message-box'>
                    {errorMessage}
                </div>
            </div>
    }
}

export { ErrorMessageView }