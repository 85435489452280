import { HandleTask } from "../../Components/Models/HandleTask/HandleTask";
import { Model } from "../../Components/Models/Model";
import { FB_APP_ID } from "../../Constants/social";
import { facebookAuthBody } from "../../DataTypes/authResponse";
import { controllers } from "../Controllers";
import { initFacebookSdk } from "./fbInit";


class FacebookLoginModel extends Model {
    private _apiInited: boolean
    private _fb: any

    private _buttonClicked: boolean
    private _handleApiInit: HandleTask;

    private _facebookUser: facebookAuthBody|null
    constructor(){
        super()
        this._apiInited = false

        this._fb = null

        this._buttonClicked = false
        this._handleApiInit = new HandleTask();
        
        this._facebookUser = null
    }

    onFBReady = () => {
        this._apiInited = true
        this.setButtonClicked(false)
        this._handleApiInit.do()
    }

    loadApi = async () => {
        if(this._apiInited){
            return
        }
        this._fb = await initFacebookSdk(FB_APP_ID)
        this.onFBReady()
    }

    

    onClickButton = () => {
        if(this._fb === null || typeof this._fb.login !== 'function'){
            this.setButtonClicked(true)
            this._handleApiInit.append(this.onClickButton)
            return
        }

        const ev = this

        this._fb.login(function(response:any){
            if (response.status === 'connected') {
                ev.responseFacebook(response.authResponse)
            } else {
                ev.failureResponseFacebook(response)
            }
        }, {scope: 'public_profile,email'});
        // this._googleAuth.signIn().then(
        //     (res:any) => this.responseGoogle(res),
        //     (err:any) => this.failureResponseGoogle(err)
        // );
    }

    responseFacebook = (data: facebookAuthBody) => {
        // console.error("FACEBOOK DATA", data)
        this._facebookUser = data
        controllers().auth.facebookSignIn(this._facebookUser)
    }

    failureResponseFacebook = (data: any) => {
        console.error("FAILURE FACEBOOK DATA", data)
    }

    setButtonClicked = (bool: boolean) => {
        if(this._buttonClicked === bool){
            return
        }
        this._buttonClicked = bool
        this.updateMe()
    }

    get buttonClicked(){
        return this._buttonClicked
    }
}

export { FacebookLoginModel }