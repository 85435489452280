import React from "react";
import {Provider} from 'react-redux';
import store from "./redux/redux";
import { AppStateController } from "./Core/AppStateController";
import { controllers } from "./Controllers/Controllers";
import { Helpers } from "./Core/Helpers";
import { MainNavigation } from "./Core/MainNavigation";

import './assets/styles/App.css'
import './assets/styles/fonts.css'
import './assets/styles/forms.css'
import './assets/styles/colors.css'

class App extends React.Component {

  render() {
    return <div className="App">
            <div className="App-box horizontal-scrollbar" id="mainscroll" ref={controllers().app.mainScroll.set}>
                <Provider store={store}>
                  <AppStateController/>
                  <Helpers/>
                  <MainNavigation/>
                </Provider>
            </div>
          </div>
  }
}

// function App() {
//   return <div className="App">
//         <div className="App-box horizontal-scrollbar" id="mainscroll" ref={controllers().app.mainScroll.set}>
//           Hello world
//             <Provider store={store}>
//               <AppStateController/>
//               <Helpers/>
//               <MainNavigation/>
//             </Provider>
//         </div>
//       </div>
// }
export default App;
