import React from "react"
import { AppSettings } from "../../Constants/AppSettings"
import { ICONS } from "../../Constants/icons"
import { controllers } from "../../Controllers/Controllers"
import { Loading } from "./Loading"

type imageTypeProps = {
    source?: string;
    alt?: string;
    altPhoto?:string;
    className?: string;
    withoutLoader?: boolean;
    CustomLoader?: any;
    customDiv?: string
}

type imageTypeState = {
    loaded: boolean;
    error: boolean;
}

const makeSource = (source:any) => {
    if(typeof source !== "string"){
        return source
    }
    return source?.indexOf(AppSettings.endpoint)===-1 ? source : source + "/" + controllers().media.w
}

class ImageView extends React.Component {
    
    props: imageTypeProps;
    state: imageTypeState;
    image = new Image();
    constructor(props: imageTypeProps){
        super(props)
        this.props = props
        this.state = { 
            loaded: false,
            error: false
        }
    }
    
    componentDidMount(){
        this.image = new Image();
        this.preloadImage()
    }

    componentWillUnmount(){
        this.image.onload = null
        this.image.onerror = null
    }

    onLoadImage = () => {
        this.makeLoaded()
    }

    onErrorImage = () => {
        // console.error('ON ERROR')
        this.makeError(true)
    }

    makeError = (error: boolean = true) => {
        this.setState({error, loaded: true})
    }

    makeLoaded = (loaded: boolean = true) => {
        this.setState({loaded})
    }

    preloadImage = () => {
        const {source} = this.props
        if(!source){
            this.makeError(true)
            return
        }
        this.image.onload = this.onLoadImage
        this.image.onerror = this.onErrorImage
        this.image.src = makeSource(source)
    }

    render(){
        const { loaded, error } = this.state
        const { source, withoutLoader, className, altPhoto, CustomLoader, customDiv} = this.props
        // const MyImage = this.image
        if(error){
            return <div className="image-error">
                <ImageView source={altPhoto ? altPhoto : ICONS.noPicture}/>
            </div>
        }
        if(!loaded){
            if(withoutLoader){
                return null
            }
            if(CustomLoader){
                return CustomLoader
            }
            return <div className="image-loading">
                <Loading size="lg"/>
            </div>
        }
        if(customDiv){
            return <div className={customDiv}>
                <input type="image" src={makeSource(source)} alt="no photo" className={className}/>
            </div>
        }
        return <input type="image" src={makeSource(source)} alt="no photo" className={className}/>
    }
}

export { ImageView }