import React from "react"

type lFormProps = {
    children?: any;
    onSubmit?: (e:any)=>void
    className?: string;
}
class LForm extends React.Component {
    props: lFormProps
    constructor(props: lFormProps){
        super(props)
        this.props = props
    }

    onSubmit = (e: any) => {
        if(typeof this.props.onSubmit !== 'function'){
            return
        }
        e.preventDefault()
        this.props.onSubmit(e)
    }
    render() {
        return <form onSubmit={this.onSubmit} className={this.props.className}>
            {this.props.children}
        </form>
    }

}

export { LForm }