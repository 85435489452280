import { loadData, UserDataProvider } from "../../Core/UserDataProvider";
import { navigator } from "../Navigator";
import { BaseAuthController } from "./BaseAuthController";


class ForgotPassword extends BaseAuthController {


    submitForm = async (e:any) => {
        const form = this.fv
        form.form(e)
        if(!form.target){
            return
        }
        const response = await loadData(
            UserDataProvider.forgotPassword,
            form.target.body,
        )
        form.afterRequest()
        if(response.statusCode === 200){
            console.log('data', response)
            navigator().navigate(`/forgotPassword/${response.data}`)
        } else {
            this.showError(response.statusMessage)
        }
    }

    backClick = () => {
        navigator().navigate('/')
    }
}

export { ForgotPassword }