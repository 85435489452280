import { Model } from "../../../../Components/Models/Model";
import { AppSettings } from "../../../../Constants/AppSettings";
import { loadData, UserDataProvider } from "../../../../Core/UserDataProvider";
import { controllers } from "../../../Controllers";


class UploadFile extends Model {
 
    private _fileRef: any
    private _responseText: string
    constructor(){
        super()
        this._fileRef = null
        this._responseText = ""
    }

    setFileRef = (ref: any) => {
        this._fileRef = ref
    }

    setResponseText = (responseText: string) => {
        this._responseText = responseText
        this.updateMe()
    }
    get responseText() {
        return this._responseText
    }

    onSubmit = async () => {
        if(!controllers().auth.userToken){
            return
        }
        // prepare file
        var file = this._fileRef.files[0];
        // prepare form data
        var formData = new FormData();
        formData.append("profile_upload", file);
        formData.append("userToken", controllers().auth.userToken||"");
        const response = await loadData(UserDataProvider.uploadFile, formData)

        console.log('RESPONSE', response)
        this.setResponseText(JSON.stringify(response))
    }

    uploadPhotoPath = () => {
        return AppSettings.endpoint+'/profile_upload'
    }
}

export { UploadFile }