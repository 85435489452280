import React from 'react'

type multiInputBoxProps = {
    children?: any
    value?: string;
    onClick?: ()=>void
}

export class MultiInputBox extends React.Component {
    props: multiInputBoxProps
    constructor(props: multiInputBoxProps){
        super(props)
        this.props = props
    }
    render(){
        const value = this.props.value !==undefined ? this.props.value : ''
        return <div className="multi-input-box" onClick={this.props?.onClick}>
            <div className="multi-input-box-container">
                {value}
            </div>
        </div>
    }
}