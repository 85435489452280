import { UPDATE } from "../../../DataTypes/UPDATE";
import { RW } from "../../../Helpers/LangHelper";
import Browsering from "../../Models/Browsering";
import { Fa } from "../Fa";
import { UpdateComponent } from "../UpdateComponent";

type lInput = {
    customStyle?: string;
    type?: string;
    name?: string; 
    defaultValue?: string; 
    placeholder?: string;
    title?: string;
    value?:string|number|boolean;
    checked?: boolean;
    checkboxText?: string;
    required?: boolean
    onTextChange?: (value: string)=>void
    autocomplete?:boolean
    disabled?: boolean;
    onFocus?: (e: any)=>void
    onBlur?: (e:any)=>void
    customButton?: {
        icon: string;
        style: string;
        click: ()=>void
        buttonColor?: string;
    }
}
type stateLInput = {
    ref: any;
    checked?: boolean;
    passwordButton?: boolean;
    passwordShowed?: boolean;
    validate?:boolean
    focused: boolean
}
class LInput extends UpdateComponent {
    props: lInput;
    state: stateLInput;
    constructor(props: lInput){
        super(props)
        this.props = props
        this.state = {
            ref: null,
            checked: props.checked||false,
            passwordButton: props.type === "password",
            passwordShowed: false,
            validate: true,
            focused: false
        }
        this.type = UPDATE.LANG
        this.onKeyUp = this.onKeyUp.bind(this)
        this.setRef = this.setRef.bind(this)
        this.clear = this.clear.bind(this)
        this.changeChecked = this.changeChecked.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    get getName(){
        return this.props.name
    }

    get required(){
        return this.props.required||false
    }

    get inputRef(){
        return this.state.ref
    }

    makeValidate = (bool: boolean) => {
        if(this.state.validate === bool){
            return
        }

        this.setState({
            validate: bool
        })
    }

    setRef(ref:any){
        this.setState({ref})
    }
    onKeyUp(){
        this.makeValidate(true)
        if(this.state.ref !== null && this.props.onTextChange){
            const value:string = this.state.ref.value
            this.props.onTextChange(value)
        }
    }
    onChange(){
        if(this.props?.checkboxText){
            this.setState({
                checked: !this.state.checked
            })
        }
    }
    changeChecked(bool: boolean){
        this.setState({
            checked: bool
        })
    }
    clear(){
        try{
            const {ref} = this.state
            ref.value = ""
            if(this.props.checkboxText){
                this.setState({
                    checked: this.props.checked||false,
                })
            }
        } catch(e){
            console.log(e)
        }
    }
    focus = () => {
        if(this.state.ref===null){
            return
        }
        this.state.ref.focus();
    }

    changePasswordType = () => {
        const showed = this.state.passwordShowed
        this.setState({passwordShowed: !showed})
        this.focus()
    }

    render(){
        const {...p} = this.props
        const {...s} = this.state

        const inputType = s.passwordButton ? (s.passwordShowed ? "text" : "password") : p?.type || "text"
        return <div className={`input ${p?.customStyle} ${!this.state.validate ? 'not-validate-input' : ''}`}>
            <div className="input-box">
                {p.title && <div className="input-title">
                    <div className="input-title-box">
                        {RW(p.title) || p.title}
                    </div>
                </div>}
                <input 
                    ref={this.setRef}
                    type={inputType} 
                    name={p?.name||"shouldToSetName"} 
                    defaultValue={p?.defaultValue||""} 
                    placeholder={RW(p?.placeholder||"") || p?.placeholder}
                    onKeyUp={this.onKeyUp}
                    onChange={this.onChange}
                    checked={this.state.checked}
                    autoComplete={p?.autocomplete?'true':'false'}
                    disabled={p?.disabled}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    // @ts-ignore
                    // autocomplete={p?.autocomplete}
                />
                {p?.checkboxText && <div className="checkbox-text">{p.checkboxText}</div>}
                {p?.customButton && <div className={`custom-input-button ${p.customButton.style}`} onClick={p.customButton.click}><Fa icon={p.customButton.icon} color={p.customButton.buttonColor}/></div>}
                {s?.passwordButton && <div className={`custom-input-button password-button ${Browsering.isSafari ? 'safari-eye' : ''}`} onClick={this.changePasswordType}><Fa icon={s.passwordShowed ? "eye": "eye-slash"} color="#C37857"/></div>}
            </div>
        </div>
    }
}

export {LInput}