import { UPDATE } from "../../../DataTypes/UPDATE"
import { RW } from "../../../Helpers/LangHelper"
import { UpdateComponent } from "../UpdateComponent"


type lAreaProps = {
    title: string;
    name: string;
    type?:string;
    placeholder?:string;
    customStyle?:string;
    required?:boolean
    value?:string
    onChange?: (e:any)=>void
}

class LArea extends UpdateComponent {
    props: lAreaProps
    constructor(props: lAreaProps){
        super(props)
        this.props = props
        this.type = UPDATE.LANG
    }
    render(){
        const p = this.props
        return <div className={`input ${p?.customStyle}`}>
                    <div className="input-box">
                        {p.title && <div className="input-title">
                            <div className="input-title-box">
                                {RW(p.title) || p.title}
                            </div>
                        </div>}
                        <textarea
                        // name={p.name}
                            className="authInput"
                            placeholder={RW(p.placeholder)||p.placeholder}
                            required={p?.required}
                            name={p?.name}
                            value={p?.value}
                            onChange={p?.onChange}
                        />
                    </div>
            </div>
    }
}

export {LArea}