import React from "react";
import { controllers } from "../Controllers/Controllers";
import { dictionary } from "../Controllers/Dictionaries";
import { navigator } from "../Controllers/Navigator";

class AppStateController extends React.Component {
    

    componentDidMount(){
        window.addEventListener('resize', controllers().media.reinit);
        navigator().menuViewingControl()
        AppStateController.restoreData()
    }

    componentWillUnmount(){
        window.removeEventListener('resize', controllers().media.reinit);
    }

    static async restoreData(){        
        await controllers().auth.restoreMe()
        await controllers().auth.checkToken();
        if(controllers().auth.isLogin){
            await controllers().auth.getUserInfo();
        }
        dictionary().loadAll()
        
    }

    render(){
        return null
    }
}

export { AppStateController }