import { loadData, UserDataProvider } from "../../Core/UserDataProvider";
import { emailToken } from "../../DataTypes/authResponse";
import { navigator } from "../Navigator";
import { BaseAuthController } from "./BaseAuthController";


class ConfirmPassword extends BaseAuthController {
    private _multiInputValue: string
    private _multiRef:any
    private _hash: string
    constructor(){
        super()
        this._multiInputValue = ""
        this._multiRef = null
        this._hash = ""
    }

    setHash = (hash:string) => {
        this._hash = hash
    }

    setMultiRef = (ref: any) => {
        this._multiRef = ref
    }

    get multiRef(){
        return this._multiRef
    }

    changeMultiValue = (value: string) => {
        this._multiInputValue = value
        this.updateMe()
    } 
    get multiValue(){
        return this._multiInputValue
    }

    submitForm = async (e:any) => {

        const emailBody:emailToken = {
            emailToken: this._hash,
            code: this._multiInputValue
        }
        console.log('Email Body', emailBody)
        this.fv.beforeRequest()
        const response = await loadData(
            UserDataProvider.emailToken,
            emailBody
        )
        this.fv.afterRequest()
        console.log('confirmforgotresponse', response)
        if(response.statusCode === 200){
            navigator().navigate(`/newpassword/${this._hash}`)
        } else if(response.statusCode === 505){
            this.showError("Code verification error");
        } else if(response.statusCode === 506){
            this.showError('Time error');
        } else {
            this.showError(response.statusMessage)
        }
    }

    backClick = () => {
        navigator().navigate('/forgotpassword')
    }

    willUnmount = () => {
        this.clearError(false)
        this._multiInputValue = ""
    }
}

export { ConfirmPassword }