import { ErrorMessageView } from '../../../Components/Views/UI/ErrorMessage/ErrorMessageView';
import { LButton } from '../../../Components/Views/UI/LButton';
import { LForm } from '../../../Components/Views/UI/LForm';
import { LInput } from '../../../Components/Views/UI/LInput';
import { SuccessMessageView } from '../../../Components/Views/UI/SuccessMessage/SuccessMessageView';
import { ViewItem } from '../../../Components/Views/ViewItem';
import { NewPassword } from '../../../Controllers/AuthControllers/NewPassword';

class NewPasswordPage extends ViewItem {

    get controller(): NewPassword {
        return this.props.controller
    }

    componentDidMount(){
        const {hash} = this.props.match.match.params
        if(hash){
            this.controller.setHash(hash)
        }
    }

    componentWillUnmount(){
        this.controller.willUnmount()
    }
    
    render(){
        const { submitForm, fv, backClick, error, success} = this.controller
        return <div className="page auth-page">
                <div className="auth-box design-bg">
                <SuccessMessageView ref={success.set} controller={success}>
                    <LForm onSubmit={submitForm} className="auth-form">
                        <div className="title">
                            <div className="title-box">
                                New password
                            </div>
                        </div>
                        <div className="auth-inputs">
                            <LInput ref={fv.set} name="password" type="password" title="Password" placeholder="Enter password" customStyle="auth-form" required/>
                            <LInput ref={fv.set} name="confirmpassword" type="password" title="Confirm password" placeholder="Enter password" customStyle="auth-form" required/>
                        </div>
                        <ErrorMessageView ref={error.set} controller={error}/>
                        <div className="auth-buttons">
                            <LButton ref={fv.setButton} title="Change password" onClick={()=>{}} type={"suit"} iconColor="white" submit/>
                            <LButton title="Back" onClick={backClick} type={"secondary"}/>
                        </div>
                    </LForm>
                </SuccessMessageView>
            </div>
        </div>
    }
}

export { NewPasswordPage }