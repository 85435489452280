import { SuccessMessage } from "../../../Models/Validators/SuccessMessage";
import { ViewItem } from "../../ViewItem";
import { LButton } from "../LButton";
import './successMessage.css'

class SuccessMessageView extends ViewItem {

    get controller(): SuccessMessage{
        return this.props.controller
    }
    render() {
        const { success, successMessage, successButton } = this.controller
        if(success){
            return <div className='success-message'>
            <div className='success-message-box'>
                <div className="success-message-title">{successMessage}</div>
                {successButton && <LButton {...successButton}/>}
            </div>
        </div>
        }
        return this.props.children
    }
}

export { SuccessMessageView }