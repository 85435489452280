import { lButton } from "../../Views/UI/LButton";
import { Model } from "../Model";

type successMessageModel = {
    successMessage?: string;
    successButton?:lButton
}
class SuccessMessage extends Model {

    private _success: boolean
    private _successMessage: string;
    private _successButton: lButton|null

    constructor(model: successMessageModel = {}){
        super()
        this._success = false
        this._successMessage = model.successMessage || ""
        this._successButton = model.successButton || null
    }

    get success(){
        return this._success
    }

    get successMessage(){
        return this._successMessage
    }
    get successButton() {
        return this._successButton
    }

    makeSuccessButton = (button: lButton) => {
        this._successButton = button
        this.updateMe()
    }

    setMessage = (message: string = "", update: boolean = true) => {
        this._success = true
        this._successMessage = message !== "" ? message : this._successMessage
        if(update){
            this.updateMe()
        }
    }

    clearMessage = (update: boolean = true) => {
        this._success = false
        this._successMessage = ""
        if(update){
            this.updateMe()
        }
    }
}

export { SuccessMessage }