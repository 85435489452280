import { browserType } from "../../DataTypes/dataTypes";

export default class Browsering {

    static get isChrome() {
        return navigator.userAgent.match(/chrome|chromium|crios/i)
    }

    static get isFirefox(){
        return navigator.userAgent.match(/firefox|fxios/i)
    }

    static get isSafari(){
        return navigator.userAgent.match(/safari/i)
    }

    static get isOpera(){
        return navigator.userAgent.match(/opr\//i)
    }

    static get isEdge(){
        return navigator.userAgent.match(/edg/i)
    }

    static get browser(): browserType{
        let userAgent = navigator.userAgent;
        let browserName:browserType;
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "Chrome";
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "Firefox";
          }  else if(userAgent.match(/safari/i)){
            browserName = "Safari";
          }else if(userAgent.match(/opr\//i)){
            browserName = "Opera";
          } else if(userAgent.match(/edg/i)){
            browserName = "Edge";
          }else{
            browserName = null;
          }

          return browserName
    }

}