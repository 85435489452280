import { HandleTask } from "../../Components/Models/HandleTask/HandleTask"
import { Model } from "../../Components/Models/Model"
import { Timeout } from "../../Components/Models/Timeout"
import { GAPI_KEY, GAUTH_CLIENT_ID } from "../../Constants/social"
import { googleAuthBody } from "../../DataTypes/authResponse"
import { controllers } from "../Controllers"

class GoogleLoginModel extends Model {
    private _scriptApenned: boolean
    private _apiInited: boolean

    private _googleAuth: any
    private _loadTimeout:Timeout;

    private _buttonClicked: boolean
    private _handleApiInit: HandleTask;

    private _user: any
    private _userData: googleAuthBody|null

    private _meta: {currentApiRequest:any, isAuthorized:boolean}
    constructor(){
        super();
        
        this._scriptApenned = false
        this._apiInited = false

        this._googleAuth = null
        this._loadTimeout = new Timeout(500);
        this._meta = {
            currentApiRequest: {},
            isAuthorized: false
        }

        this._buttonClicked = false
        this._handleApiInit = new HandleTask();

        this._user = null
        this._userData = null
    }

    initClient = (gapi: any) => {
        if(this._apiInited){
            // console.error("API INITED");
            return
        }
        const ev = this
        if(typeof gapi.client === 'undefined'){
            this._loadTimeout.set(()=>this.initClient(gapi))
            return
        }
        gapi.client.init({
            'apiKey': GAPI_KEY,
            'clientId': GAUTH_CLIENT_ID,
            'scope': 'https://www.googleapis.com/auth/userinfo.profile',
            'discoveryDocs': ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
        }).then(function () {
            
            ev._googleAuth = gapi?.auth2.getAuthInstance();
      
            // Listen for sign-in state changes.
            ev._googleAuth.isSignedIn.listen(ev.updateSigninStatus);
            ev.onGapiReady()
        });
    }

    onGapiReady = () => {
        this._apiInited = true
        this.setButtonClicked(false)
        this._handleApiInit.do()
    }

    loadApi = () => {
        if(this._scriptApenned){
            return
        }
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";

        script.onload = () => {
            // @ts-ignore
            this.initClient(gapi)
            this._scriptApenned = true
        }
    
        document.body.appendChild(script);
    }

    onClickButton = () => {
        if(this._googleAuth === null || typeof this._googleAuth.signIn !== 'function'){
            this.setButtonClicked(true)
            this._handleApiInit.append(this.onClickButton)
            return
        }
        this._googleAuth.signIn().then(
            (res:any) => this.responseGoogle(res),
            (err:any) => this.failureResponseGoogle(err)
        );
    }

    responseGoogle = (data: any) => {
        var user = this._googleAuth.currentUser.get();
        this._user = this._googleAuth.currentUser
        this._userData = {
            id_token: user.getAuthResponse().id_token,
            access_token: user.getAuthResponse().access_token,
            expires_at: user.getAuthResponse().expires_at,
            expires_in: user.getAuthResponse().expires_at,
            userID: user.getId()
        }
        controllers().auth.googleSignIn(this._userData)
    }

    failureResponseGoogle = (data: any) => {
        console.error("FAILURE RESPONSE", data)
    }

    sendAuthorizedApiRequest = (requestDetails:any) => {
        console.error("sendAuthorizedApiRequest", requestDetails)
        this._meta.currentApiRequest = requestDetails;
        if (this._meta.isAuthorized) {
        // Make API request
        // gapi.client.request(requestDetails)

        // Reset currentApiRequest variable.
            this._meta.currentApiRequest = {};
        } else {
            this._googleAuth.signIn();
        }
    }
      
      /**
       * Listener called when user completes auth flow. If the currentApiRequest
       * variable is set, then the user was prompted to authorize the application
       * before the request executed. In that case, proceed with that API request.
       */
      updateSigninStatus = (isSignedIn:boolean) => {
        console.error("updateSigninStatus", isSignedIn)
        if (isSignedIn) {
          this._meta.isAuthorized = true;
          if (this._meta.currentApiRequest) {
            this.sendAuthorizedApiRequest(this._meta.currentApiRequest);
          }
        } else {
          this._meta.isAuthorized = false;
        }
      }


    setButtonClicked = (bool: boolean) => {
        if(this._buttonClicked === bool){
            return
        }
        this._buttonClicked = bool
        this.updateMe()
    }

    get buttonClicked(){
        return this._buttonClicked
    }
}

export { GoogleLoginModel }