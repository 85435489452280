

class TargetForm {
    _event;
    _targetForm: Array<{name: string; value: string}>;
    constructor(e: any){
        e.preventDefault();
        this._event = e
        this._targetForm = e.target.elements
    }
    getInput(name:string){
        return Array.from(this._targetForm).find(e=>e.name  === name)
    }
    getInputValue(name:string) : string{
        return Array.from(this._targetForm).find(e=>e.name === name)?.value || ""
    }
    serialize(){
        const newArr : {[key: string]: string} = {};
        Array.from(this._targetForm).forEach((e:{name: string; value: string})=>{
            if(e.name!==undefined && e.name !== ''){
                newArr[e.name] = e.value
            }
        })
        return newArr
    }
    get body() {
        return this.serialize()
    }
}

export { TargetForm }
