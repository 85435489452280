

class NavigatorImpl {
    // set main navigation function
    private _nav: any
    private _beforeAuth: string = "/";
    constructor() {
        this._nav = null
    }

    private get nav(){
        return this._nav
    }
    private set nav(value){
        this._nav = value
    }

    initNavigation(navigation: any){
        this.nav = navigation
    }

    private validateNav(){
        return this._nav && this._nav?.history
    }

    private locate(path:string){
        if(!this.validateNav()){
            return
        }
        this._nav.history.push(path)
        // history.push(path);

    }

    navigate(path: string){
        this.locate(path)
        this.afterLocateFunctions()
        // this.menuViewingControl()
    }

    redirect(link: string, target:"_blank"|"" = ""){
        window.location.href = link
    }

    afterLocateFunctions(){
        
    }
    // if screen width < 600 hide menu
    menuViewingControl(){
        // if(controllers().media.w<600){
        //     controllers().menu.hide()
        // }
    }

    savePathBeforeNavigate = () => {
        this._beforeAuth = window.location.pathname;
        // console.error("PATH SAVED", this._beforeAuth);
    }

    removeRedirectPath() {
        this._beforeAuth = '/'
    }

    navigateAuthPath = (defaultPath: string) => {
        if(this._beforeAuth !== '/'){
            // console.error('BEFORE AUTH EXIST', this._beforeAuth)
            this.navigate(this._beforeAuth);
            this._beforeAuth = ''
            return
        }
        console.error('NOT BEFORE EXIST', this._beforeAuth)
        this.navigate(defaultPath)
    }
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.navigator =
  // @ts-ignore
  global.__app__.navigator || new NavigatorImpl();

export function navigator(): NavigatorImpl {
  // @ts-ignore
  return global.__app__.navigator;
}
