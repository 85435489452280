import { ErrorMessageView } from '../../../Components/Views/UI/ErrorMessage/ErrorMessageView';
import { LButton } from '../../../Components/Views/UI/LButton';
import { LForm } from '../../../Components/Views/UI/LForm';
import { MultiInput } from '../../../Components/Views/UI/MultiInput/MultiInput';
import { ViewItem } from '../../../Components/Views/ViewItem';
import { ConfirmPassword } from '../../../Controllers/AuthControllers/ConfirmPassword';

class ConfirmPasswordPage extends ViewItem {

    get controller(): ConfirmPassword {
        return this.props.controller
    }

    componentDidMount(){
        const {hash} = this.props.match.match.params
        if(hash){
            this.controller.setHash(hash)
        }
    }

    componentWillUnmount(){
        this.controller.willUnmount()
    }
    
    render(){
        const { submitForm, fv, backClick, multiValue, changeMultiValue, setMultiRef, error} = this.controller
        return <div className="page auth-page">
                <div className="auth-box design-bg">
                <LForm onSubmit={submitForm} className="auth-form">
                    <div className="title">
                        <div className="title-box">
                            Forgot password
                        </div>
                    </div>
                    <div className="auth-inputs">
                        <MultiInput value={multiValue} onChangeValue={changeMultiValue} maxLength={6} setRef={setMultiRef}/>
                        <ErrorMessageView ref={error.set} controller={error}/>
                    </div>
                    <div className="auth-buttons">
                        <LButton 
                            ref={fv.setButton}
                            title="Done" 
                            onClick={()=>{}} 
                            type={"suit"}
                            icon="check"
                            iconColor='white'
                            submit
                        />
                        <LButton title="Back" onClick={backClick} type={"secondary"}/>
                    </div>
                </LForm>
            </div>
        </div>
    }
}

export { ConfirmPasswordPage }