import { ErrorMessage } from "../../../Components/Models/Validators/ErrorMessage";
import { FormValidator } from "../../../Components/Models/Validators/FormValidator";
import { SuccessMessage } from "../../../Components/Models/Validators/SuccessMessage";
import { loadData, UserDataProvider } from "../../../Core/UserDataProvider";
import { PageModel } from "../PageModel";


class RegisterPageModel extends PageModel {
    private _formValidator: FormValidator;
    private _error: ErrorMessage
    private _success: SuccessMessage
    constructor(pageName: string){
        super(pageName)
        this._formValidator = new FormValidator();
        this._error = new ErrorMessage();
        this._success = new SuccessMessage({
            successMessage: "Registration successful. Confirm your email to login."
        })
    }

    get fv(){
        return this._formValidator
    }

    get error(){
        return this._error
    }

    get success(){
        return this._success
    }

    setSuccess = () => {
        this._success.setMessage()
    }

    clearSuccess = (update:boolean = false) => {
        this._success.clearMessage(update)
    }

    showError(mess: string){
        this._error.showError(mess)
    }

    clearError = (update: boolean = true) => {
        this.error.clearError(update)
    }

    willUnmount = () => {
        this._error.clearError(false)
        this._success.clearMessage(false)
    }

    onSubmitRegister = async (e:any) => {
        const form = this._formValidator
        form.form(e, false)
        if(!form.target){
            return
        }
        console.log("BODY", form.body)
        if(form.target.getInputValue('password') !== form.target.getInputValue('confirmpassword')){
            this.showError('Password do not match')
            return
        }
        form.beforeRequest()
        const response = await loadData(
            UserDataProvider.registration,
            {
                ...form.body,
            }
        )
        form.afterRequest()
        // console.log(response.status)
        if(response.statusCode===200){
            this.setSuccess()
        } else {
            this.showError(response.statusMessage)
        }
    }
}

export { RegisterPageModel }