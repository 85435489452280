import { ViewItem } from "../../../Components/Views/ViewItem";
import { GoogleAuthView } from "./SocialViews/GoogleAuthView";
import { controllers } from "../../../Controllers/Controllers";
import { FacebookAuthView } from "./SocialViews/FacebookAuthView";


class SocialAuthView extends ViewItem {

    responseGoogle = (data: any) => {
        console.error("Google Data:",data)
    }

    responseFacebook = (data: any) => {
        console.error("Facebook Data:",data)
    }

    shouldComponentUpdate(){
        return false
    }

    render() {
        const {googleLogin, facebookLogin} = controllers().social
        return <div className="auth-another-methods">
                <GoogleAuthView ref={googleLogin.set} controller={googleLogin} />
                <FacebookAuthView ref={facebookLogin.set} controller={facebookLogin}/>
            </div>
    }
}

export { SocialAuthView }