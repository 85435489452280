import { ConfirmPassword } from "./AuthControllers/ConfirmPassword";
import { ForgotPassword } from "./AuthControllers/ForgotPassword";
import { NewPassword } from "./AuthControllers/NewPassword";
import { controllers } from "./Controllers";


class RegisterController {
    
    private _forgotPassword: ForgotPassword
    private _confirmForgot: ConfirmPassword
    private _newPassword: NewPassword;

    constructor(){
        this._forgotPassword = new ForgotPassword();
        this._confirmForgot = new ConfirmPassword();
        this._newPassword = new NewPassword();
    }

    get forgotPassword(){
        return this._forgotPassword
    }

    get confirmForgot(){
        return this._confirmForgot
    }

    get newPassword(){
        return this._newPassword
    }

    showError(mess: string){
        return controllers().alert.error(mess)
    }

    showAlert(mess: string){
        return controllers().alert.success(mess)
    }

    // async confirmforgot(hash:string, value:string){
    //     this.setDisabled(true)
    //     const response = await loadData(
    //         UserDataProvider.emailToken,
    //         {
    //             emailToken: hash,
    //             code: value
    //         }
    //     )
    //     this.setDisabled(false)
    //     console.log('confirmforgotresponse', response)
    //     if(response.statusCode === 200){
    //         this.showAlert('Good')
    //         navigator().navigate(`/newPass/${hash}`)
    //     } else if(response.statusCode === 505){
    //         this.showError("Code verification error");
    //     } else if(response.statusCode === 506){
    //         this.showError('Time error');
    //         navigator().navigate('/forgotpass')
    //     } else {
    //         this.showError(response.statusMessage)
    //     }
    //     // refernce.focus();
    // }

    // async newpassword(form: FormValidator, hash:string){
    //     if(!form.target){
    //         return
    //     }
    //     if(form.target.getInputValue('password')!==form.target.getInputValue('confirmpassword')){
    //         this.showError(`Password do'nt match`)
    //         return
    //     }
    //     this.setDisabled(true)
    //     const response = await loadData(
    //         UserDataProvider.changePass,
    //         {
    //             ...form.body,
    //             emailToken: hash,
    //         }
    //     )
    //     this.setDisabled(false)
    //     if(response.statusCode === 200){
    //         this.showAlert('Password changed')
    //         navigator().navigate(`/`)
    //     } else if (response.statusCode === 507 || response.statusCode === 508) {
    //         this.showError(response.statusMessage)
    //     } else {
    //         this.showError(response.statusMessage)
    //         navigator().navigate('/forgotpass')
    //     }
    // }
}

export { RegisterController }