import React from 'react'
import { AlertView } from '../Components/Views/Alert/AlertView'

class Helpers extends React.Component {
    render(){
        // main navigation
        return <div className="helpers">
            <div className="helpers-box">
                <AlertView/>
                {/* <MainLoader/> */}
            </div>
        </div>
    }
}

export { Helpers }