import React from 'react'
import { MultiInputBox } from './MultiInputBox'
import './multiInput.css'

type multiInputProps = {
    children?: any;
    maxLength: number;
    value: string;
    onChangeValue: (val: string)=>void
    setRef: (ref:any)=>void
}

type multiInputState = {
    inputRef: any,
    value: string,
    maxLength: number,
}

class MultiInput extends React.Component {
    props: multiInputProps
    state: multiInputState
    constructor(props:multiInputProps){
        super(props)
        this.props = props
        this.changeValue = this.changeValue.bind(this)
        this.onPressInputBox = this.onPressInputBox.bind(this)
        this.setReference = this.setReference.bind(this)
        this.state = {
            inputRef: null,
            value: props.value,
            maxLength: props.maxLength,
        }
    }

    changeValue(e:any){
        // console.log('e', )
        const value = e.target.value
        if(value.length>this.props.maxLength){
            return
        }
        this.props.onChangeValue(e.target.value)
    }
    onPressInputBox(){
        if(this.state.inputRef!==null){
            this.state.inputRef.focus()
        }
    }

    setReference(ref:any){
        if(this.state.inputRef === null){
            this.props.setRef(ref)
            this.setState({
                inputRef: ref
            })
            ref.focus()
        }
    }
    
    render() {
        const inputs = []
        for (let i = 0; i < this.state.maxLength; i++) {
            inputs.push({
                value: this.props.value.charAt(i),
                onClick: this.onPressInputBox
            })
        }
        return <div className="multi-input">
            <div className="CodeInputBox">
                <input
                    type="number"
                    ref={this.setReference}
                    className="CodeInput"
                    value={this.props.value} 
                    onChange={this.changeValue}
                />
            </div>
            <div className="multi-input-container">
                {inputs.map((e, index)=>{
                    return <MultiInputBox key={index} {...e}/>
                })}
            </div>
        </div>
    }
}

export { MultiInput }