import React from 'react'
import { LButton } from '../../../Components/Views/UI/LButton';
import { LForm } from '../../../Components/Views/UI/LForm';
import { LInput } from '../../../Components/Views/UI/LInput';
import { ViewItem } from '../../../Components/Views/ViewItem';
import { ForgotPassword } from '../../../Controllers/AuthControllers/ForgotPassword';

class ForgotPasswordPage extends ViewItem {

    get controller(): ForgotPassword {
        return this.props.controller
    }
    
    render(){
        const { submitForm, fv, backClick} = this.controller
        return <div className="page auth-page">
                <div className="auth-box design-bg">
                <LForm onSubmit={submitForm} className="auth-form">
                    <div className="title">
                        <div className="title-box">
                            Forgot password
                        </div>
                    </div>
                    <div className="auth-inputs">
                        <LInput 
                            ref={fv.set}
                            type="email" 
                            name="email" 
                            title="Email" 
                            placeholder="Enter email" 
                            customStyle="auth-form" 
                            required
                        />
                    </div>
                    <div className="auth-buttons">
                        <LButton 
                            ref={fv.setButton}
                            title="Forgot" 
                            onClick={()=>{}} 
                            type={"suit"}
                            icon="brain"
                            iconColor='white'
                            submit
                        />
                        <LButton 
                            title="Back" 
                            onClick={backClick} 
                            type={"secondary"}
                        />
                    </div>
                </LForm>
            </div>
        </div>
    }
}

export { ForgotPasswordPage }