import { Model } from "../../Components/Models/Model";
import { ErrorMessage } from "../../Components/Models/Validators/ErrorMessage";
import { FormValidator } from "../../Components/Models/Validators/FormValidator"
import { SuccessMessage } from "../../Components/Models/Validators/SuccessMessage";


class BaseAuthController extends Model {
    private _fv: FormValidator;
    private _error: ErrorMessage;
    private _success: SuccessMessage;

    constructor(){
        super()
        this._fv = new FormValidator();
        this._error = new ErrorMessage();
        this._success = new SuccessMessage();
    }

    submitForm = async (e:any) => {

    }

    backClick = () => {
    }

    get success(){
        return this._success
    }
    set success(val){
        this._success = val
    }
    get error(){
        return this._error
    }

    showSuccess = (message: string = "", update: boolean = true) => {
        this._success.setMessage(message, update)
    }
    clearSuccess = (update: boolean = true) => {
        this._success.clearMessage(update)
    }

    showError = (message: string) => {
        this._error.showError(message)
    }

    clearError = (update: boolean = true) => {
        this._error.clearError(update)
    }

    clearAll = (update:boolean = false) => {
        this._success.clearMessage(update)
        this._error.clearError(update)
    }

    get fv(){
        return this._fv
    }

}

export { BaseAuthController }