import { authBody, changePass, changePasswordRequest, emailToken, forgotBody, googleAuthBody, registrationBody, tokenBody, unsubscribeBody, verifyEmail } from "../DataTypes/authResponse"
import {baseResponse} from "../DataTypes/baseResponse"
import { fetchData } from "../Helpers/FetchData"

export class UserDataProvider {

    // HOME METHODS
    static check(body: tokenBody) {
        return fetchData(
            'check_token',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }

    // AUTH STACK METHODS
    static authorization(body: authBody) {
        return fetchData(
            'auth',
            'POST',
            {
                login: body.login,
                password: body.password,
                remember_me: body.remember_me
            }
        )
    }

    static googleAuthorization(body: googleAuthBody) {
        return fetchData(
            'google/login/callback',
            'POST',
            {
                access_token: body.access_token,
                id_token: body.id_token,
                expires_in: body.expires_in,
                expires_at: body.expires_at,
                userID: body.userID,
            }
        )
    }
    static facebookAuthorization(body: googleAuthBody) {
        return fetchData(
            'facebook/login/callback',
            'POST',
            {
                access_token: body.access_token,
                id_token: body.id_token,
                expires_in: body.expires_in,
                expires_at: body.expires_at,
                userID: body.userID,
            }
        )
    }
    static logout(body: tokenBody) {
        return fetchData(
            'logout',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }

    static userInfo(body: tokenBody){
        return fetchData(
            'clientinfo',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }


    // REGISTRATION

    static registration(body: registrationBody) {
        return fetchData(
            'register',
            'POST',
            {
                email: body.email,
                password: body.password,
                confirmpassword: body.confirmpassword,
            }
        )
    }

    static forgotPassword(body: forgotBody){
        return fetchData(
            'reset_password',
            'POST',
            {
                email: body.email
            }
        )
    }

    static emailToken(body: emailToken){
        return fetchData(
            'check_emailhash_code',
            'POST',
            {
                emailToken: body.emailToken,
                code: body.code
            }
        )
    }

    static verifyEmail(body: verifyEmail){
        return fetchData(
            'verify_email',
            'POST',
            {
                verifyToken: body.verifyToken
            }
        )
    }

    static unsubscribe(body: unsubscribeBody){
        return fetchData(
            'unsubscribe',
            'POST',
            {
                unsubscribeToken: body.unsubscribeToken
            }
        )
    }

    static changePass(body: changePass){
        return fetchData(
            'changePass',
            'POST',
            {
                emailToken: body.emailToken,
                password: body.password,
                confirmpassword: body.confirmpassword
            }
        )
    }

    static changePassword(body: changePasswordRequest) {
        return fetchData(
            'user/resetpassword',
            'POST',
            {
                userToken: body.userToken,
                password: body.password,
                newpassword: body.newpassword,
                confirmpassword: body.confirmpassword
            }
        )
    }

    static uploadFile(body:any) {
        return fetchData(
            'profile_upload',
            'POST',
            body,
            true
        )
    }

}

export async function loadData(dataProvider: (body:any)=>any, body: {}) : Promise<baseResponse> {
    try { 
        const response:baseResponse = await dataProvider(body)
        if(response.statusCode<200){ // system error exception
            console.error("STATUS CODE", response.statusMessage)
        }
        return response
    } catch(e){
        console.log('error', e)
        throw e
    }
}